import { Button, Datepicker, Icon, Loading, Modal, Pagination, Table, type OrugaOptions, OrugaConfig } from '@oruga-ui/oruga-next';
import { bulmaConfig } from '@oruga-ui/theme-bulma'

export default defineNuxtPlugin((nuxtApp) => {
    const customConfig: OrugaOptions = {
        // ...OrugaConfig,
        ...bulmaConfig,
        iconComponent: 'font-awesome-icon',
        iconPack: 'fas',
    }

    nuxtApp.vueApp
        .use(OrugaConfig, customConfig)
        .use(Loading, customConfig)
        .use(Button, customConfig)
        .use(Icon, customConfig)
        .use(Datepicker, customConfig)
        .use(Table, customConfig)
        .use(Modal, customConfig)
        .use(Pagination, customConfig)
})